import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Form from '../components/Form';
import '../styles/layout.css';
import audrey from '../../static/audrey-wide.png';
import simultaantolken from '../../static/simultaantolken.jpg';
import verbindingstolken from '../../static/verbindingstolken.jpg';
import badge from '../../static/kudo-interpreter-badge.png';
import stempel from '../../static/stempel.jpg';
import sdl from '../../static/SDL.png';

export default () => (
  <div>
    <Helmet>
      <title>B&D – Audrey Fromont</title>
      <meta
        name="description"
        content="Beëdigd vertaler, beëdigd tolk, conferentietolk."
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="RSI, Kudo, Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua Qua
vertaler + tandheelkunde, SDL Trados, Translator + dentistry, dental translator, Dutch"
      />
    </Helmet>
    <Layout>
      <div className="columns">
        <div className="column is-full">
          <img src={audrey} />
        </div>
      </div>
      <div className="container is-fluid">
        <hr />
        <nav className="level">
          <h3
            style={{ fontFamily: 'Dancing Script' }}
            className="introItem level-item has-text-centered is-size-6-mobile"
          >
            Sworn translator- sworn interpreter - conference interpreter
          </h3>
        </nav>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">
              Simultaneous translation (orally) or conference interpreting
            </h3>
            <p className="is-size-7-mobile text-normal">
              <u>Remote Simultaneous Interpreting platforms (RSI) I use</u>
            </p>
            <img className="mb-3" src={badge} alt="" width="100" />
            <br />
            <p className="is-size-7-mobile">
              Equally proficient with{' '}
              <strong>
                Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua Qua,
              </strong>{' '}
              …
            </p>
            <p className="is-size-7-mobile">
              Simultaneous translation is carried out with equipment, such as
              microphones and headsets and it is often done from an interpreting
              booth. Conversations or speeches can therefore be followed
              simultaneously in the original (source) language or in the
              translated (target) language. You can call on me for simultaneous
              translation into Dutch and into English. For the past 20 years, I
              have built trusting relationships with interpreting agencies in
              Belgium and abroad.
            </p>
            <img src={simultaantolken} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Liaison (orally)</h3>
            <p className="is-size-7-mobile">
              No technical equipment is required for this type of interpreting.
              As a (sworn) interpreter I can assist with driving tests and
              interrogations by police officers, interviews by labour inspection
              services, the Guardianship Service etc. For this type of
              interpreting, I can help you with Dutch, English, French, Spanish
              and Chinese (Mandarin).
            </p>
            <img src={verbindingstolken} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">
              Sworn translations (in writing)
            </h3>
            <p className="is-size-7-mobile">
              You have been asked to submit a sworn translation? Just use the
              contact form to get in touch with me and I’ll help you with Dutch,
              English, French and Spanish documents. As I have taken an oath
              before the Courts of First Instance of Antwerp, Brussels, Ghent,
              Mechelen, Leuven and Veurne, my signature on your documents (=
              sworn translation) can be legalised in all these cities, if need
              be. Abroad, you may be asked to provide an apostille with your
              sworn translation. Click
              <a className="is-lowercase" href="http://diplomatie.belgium.be">
                {' '}
                here{' '}
              </a>
              (link) for more detailed information.
            </p>
            <img src={stempel} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">General Translations</h3>
            <p className="is-size-7-mobile">
              <strong style={{ color: 'white' }}>
                Field of expertise: dental. Translations into Dutch of
                administrative, notarial, legal, business related or financial
                documents; fashion, design, home decoration.
              </strong>
            </p>
            <p>
              Whether you have several pages or simply one paragraph, just ask
              for a quote using the contact form. Rates and delivery times will
              be sent to you as soon as possible.{' '}
            </p>
            <p>
              Translations into Dutch (my mother tongue) are handled exclusively
              by me and translations into other languages are proofread by my
              colleagues who are native speakers of English, French and Spanish.
            </p>
            <img src={sdl} alt="" />
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">Membership</h3>
            <p className="is-size-7-mobile">
              Lextra Lingua Belgica - Professional Association of Certified
              Translators
            </p>
            <p className="is-size-7-mobile">
              Centre for Translation Studies (CETRA)
            </p>
            <p className="is-size-7-mobile">
              Belgian Association of Anglicists in Higher Education (BAAHE)
            </p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Degrees</h3>
            <p className="is-size-7-mobile">
              Master in Translation. University of Antwerp
            </p>
            <p className="is-size-7-mobile">
              Master in Conference Interpreting. Vlekho Brussels
            </p>
            <p className="is-size-7-mobile">
              PhD in Applied Linguistics: Translation Studies. Katholieke
              Universiteit Leuven (Catholic University of Leuven)
            </p>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">CONTACT</h3>
            <p className="is-size-7-mobile">Astene (Deinze)</p>
            <p className="is-size-7-mobile">audrey.fromont@gmail.com</p>
            <p className="is-size-7-mobile">+32(0)475 29 20 28</p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Contact form</h3>
            <Form
              firstLabel="Name:"
              secondLabel="E-mail address:"
              thirdLabel="Message:"
              btnLabel="Send"
              firstOption="- a private individual"
              secondOption="- a company / an association / an agency "
              thirdOption="- a police officer / social inspection officer "
              fourthOption="- a university staff member"
              selectLabel="You are:"
            />
          </div>
        </div>
      </div>
    </Layout>
  </div>
);
